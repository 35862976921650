<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3 mb-3">
        <h3>Search</h3>
      </div>
    </div>

    <div class="row pt-4">
      <div class="col-md-12 input-group input-group-lg mb-5 shadow-1 rounded">
        <input
          v-model="query"
          type="text"
          class="form-control shadow-inset-2"
          id="filter-icon"
          aria-label="type 2 or more letters"
          placeholder="Search the Vault..."
          value=""
          autocomplete="off"
        />
        <div class="input-group-append">
          <button
            class="btn btn-hover hidden-sm-down waves-effect waves-themed"
            type="button"
            @click="queryChangeHandler"
          >
            <i class="ri-search-2-line"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div
            class="col-md-4 mt-4"
            v-for="post in filteredPosts"
            v-bind:key="post.id"
          >
            <post-card :post="post"></post-card>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="filteredDocs.length">
      <h4>Document Results</h4>
      <ul class="list-group mt-2">
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
          v-for="doc in filteredDocs"
          :key="doc.id"
        >
          {{ doc.Title }}
          <a :href="doc.Document" target="_blank">
            <i class="fa fa-download text-danger"></i>
          </a>
        </li>
      </ul>
    </div>

    <h4 v-if="!filteredPosts.length && !filteredDocs.length && this.searched">
      No results. Please try your search again.
    </h4>
    <br /><br />
  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from "../components/PostCard.vue";
import _ from "lodash";
export default {
  components: { PostCard },
  data() {
    return {
      query: "",
      queryString: "",
      posts: [],
      documents: [],
      currentPage: 1,
      filteredPosts: [],
      filteredDocs: [],
      searched: false,
    };
  },
  mounted() {
    this.$bind("posts", db.collection("posts").where("Section", "!=", "docs"));
    this.$bind(
      "documents",
      db.collection("posts").where("Section", "==", "docs")
    );
    if (this.$route.query.tag) {
      // this.query = this.$route.query.tag;
      this.search()
    }
  },
  watch: {
    query: {
      handler: "queryChangeHandler",
    }
  },
  methods: {
    search() {
      if (this.$route.query.tag){
        this.query = this.$route.query.tag
      }
  
      this.searched = true;

      //POSTS
      let filtered = [];

      if (this.query == "") return;

      for (let post of this.posts) {
        if (post.Title) {
          const postTitle = post.Title.toString().toLowerCase();
          if (postTitle.includes(this.query.toLowerCase())) {
            filtered.push(post);
          }
        }
        if (post.Category && post.Category.length) {
          const postCategory = post.Category;
          if (postCategory.includes(this.query.toLowerCase())) {
            filtered.push(post);
          }
        }

        this.filteredPosts = _.uniq(filtered);
      }

      let filteredDocs = [];
      for (let post of this.documents) {
        if (post.Title) {
          console.log("ddddd", post);
          const postTitle = post.Title.toString().toLowerCase();
          if (postTitle.includes(this.query.toLowerCase())) {
            filteredDocs.push(post);
          }
        }
        if (post.Category && post.Category.length) {
          const postCategory = post.Category;
          if (postCategory.includes(this.query.toLowerCase())) {
            filteredDocs.push(post);
          }
        }

        this.filteredDocs = _.uniq(filteredDocs);
      }
    },
    async queryChangeHandler() {
      this.search();
    },
  },
};
</script>

<style>
.list-group-item {
  background-color: #2c2c2c;
  color: #ffffff;
}
</style>